import React from "react";

const LMask = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement> & { style?: React.CSSProperties }
>((props, ref) => {
  const { style, ...svgProps } = props;
  return (
    <svg
      width="795"
      height="313"
      viewBox="0 0 795 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <defs>
        <mask id="maskL">
          <path
            ref={ref}
            // d="M28.7587 -3C28.7587 -3 9.75778 101 22.2583 146C34.7589 191 40.7588 192.5 40.7588 192.5C40.7588 192.5 46.7588 197.5 58.2582 197.5C72.9328 197.5 92.7588 174 92.7588 174"
            strokeLinecap="round"
            d="M29.5006 18.5C29.5006 18.5 19.3952 76.6336 21.5005 114C23.0354 141.243 22.3154 174 32.5008 183C40.0301 189.653 46.7592 197.5 58.2587 197.5C72.9332 197.5 72.5004 190.5 72.5004 190.5"
            stroke="white"
            strokeWidth="58"
            {...svgProps}
          />
        </mask>
      </defs>
      <path
        d="M50.0365 217.029C45.7381 217.029 41.2528 216.469 36.5807 215.347C32.0954 214.039 27.7035 211.61 23.4051 208.059C19.1067 204.508 15.1821 199.088 11.6312 191.8C8.0804 184.324 5.27709 174.606 3.22133 162.645C1.16558 150.684 0.137695 135.734 0.137695 117.792C0.137695 104.523 0.511471 92.2823 1.25902 81.0691C2.00657 69.8558 3.03444 59.2033 4.34265 49.1114C5.65086 39.0195 7.14596 29.1145 8.82794 19.3963C9.94927 12.8553 12.005 8.18313 14.9952 5.37981C17.9854 2.38963 22.0969 0.894531 27.3298 0.894531C34.6184 0.894531 40.225 3.60439 44.1496 9.02411C48.2611 14.2569 50.3169 22.7603 50.3169 34.5342C50.3169 39.9539 49.9431 45.5605 49.1955 51.354C48.6349 57.1475 47.8873 63.5951 46.9529 70.6968C46.2054 77.6117 45.4578 85.4609 44.7103 94.2446C44.1496 102.841 43.8693 112.56 43.8693 123.399C43.8693 135.173 44.1496 144.517 44.7103 151.432C45.2709 158.347 46.1119 163.486 47.2332 166.85C48.5414 170.027 49.9431 172.176 51.4382 173.298C52.9333 174.232 54.5218 174.699 56.2038 174.699C59.9415 174.699 63.1186 174.232 65.735 173.298C68.5383 172.176 70.9679 171.149 73.0236 170.214C75.2663 169.28 77.6024 168.813 80.0319 168.813C84.7041 168.813 87.8812 170.401 89.5631 173.578C91.2451 176.755 92.0861 180.773 92.0861 185.632C92.0861 192.173 90.1238 197.78 86.1992 202.452C82.4614 207.124 77.4155 210.769 71.0613 213.385C64.8941 215.815 57.8858 217.029 50.0365 217.029Z"
        fill="#5A5A5A"
        fillOpacity="1"
        mask="url(#maskL)"
      />
    </svg>
  );
});

export default LMask;
