import React from "react";

const TMask = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement> & { style?: React.CSSProperties }
>((props, ref) => {
  const { style, ...svgProps } = props;
  return (
    <svg
      width="795"
      height="313"
      viewBox="0 0 795 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <defs>
        <mask id="maskT">
          <path
            ref={ref}
            // d="M502.259 4.5C502.259 4.5 506.474 9.99215 507.759 12C513.327 20.7055 515.952 26.3249 517.759 36.5C519.261 44.9593 517.759 49.9085 517.759 58.5C517.759 66.3105 518.12 70.6979 517.759 78.5C517.378 86.7293 516.652 91.3104 515.759 99.5C514.585 110.262 513.7 116.27 512.259 127C511.314 134.034 509.877 137.904 509.759 145C509.624 153.064 510.248 157.69 512.259 165.5C513.336 169.686 513.639 172.233 515.759 176C517.464 179.03 519.09 180.283 521.259 183C523.77 186.146 524.806 188.264 527.759 191C533.396 196.223 537.638 199.008 545.259 200C548.163 200.378 549.83 200 552.759 200C556.859 200 559.216 200.688 563.259 200C566.486 199.451 568.36 199.019 571.259 197.5C573.853 196.14 574.834 194.642 577.259 193C580.448 190.841 582.709 190.351 585.759 188C589.588 185.048 594.259 179 594.259 179"
            strokeLinecap="round"
            d="M512.259 28.5C513.76 36.9593 517.759 49.9085 517.759 58.5C517.759 66.3105 518.119 70.6979 517.759 78.5C517.378 86.7293 516.652 91.3104 515.759 99.5C514.585 110.262 513.699 116.27 512.259 127C511.314 134.034 509.877 137.904 509.759 145C509.624 153.064 510.248 157.69 512.259 165.5C513.336 169.686 513.638 172.233 515.759 176C517.464 179.03 519.09 180.283 521.259 183C523.77 186.146 524.806 188.264 527.759 191C533.396 196.223 537.638 199.008 545.259 200C548.163 200.378 549.83 200 552.759 200C556.859 200 559.216 200.688 563.259 200C566.485 199.451 568.359 199.019 571.259 197.5C573.853 196.14 574.833 194.642 577.259 193"
            stroke="white"
            strokeWidth="60"
            {...svgProps}
          />
        </mask>
      </defs>
      <path
        d="M515.302 213.612C524.646 220.34 537.261 223.704 553.147 223.704C566.603 223.704 576.788 220.62 583.703 214.453C590.804 208.285 594.355 200.436 594.355 190.905C594.355 185.859 593.327 182.121 591.272 179.692C589.403 177.075 586.319 175.767 582.021 175.767C579.778 175.767 577.629 176.047 575.573 176.608C573.704 177.169 571.649 177.823 569.406 178.57C567.163 179.318 564.173 179.692 560.435 179.692C554.268 179.692 549.035 178.477 544.737 176.047C540.438 173.618 537.261 169.6 535.206 163.993C533.15 158.2 532.122 150.537 532.122 141.006C532.122 136.298 532.206 131.815 532.374 127.555C532.379 127.44 532.383 127.324 532.388 127.209C532.526 123.815 532.718 120.565 532.963 117.458C533.711 110.357 534.551 103.629 535.486 97.2745C536.141 92.8183 536.751 88.4999 537.314 84.3194C537.554 82.5389 537.786 80.7835 538.009 79.053C538.943 73.0726 539.597 66.9988 539.971 60.8315C540.345 42.5166 537.822 29.1542 532.402 20.7443C526.983 12.3344 519.414 8.12939 509.695 8.12939C503.154 8.12939 498.856 9.53105 496.8 12.3343C494.745 15.1377 493.717 18.6885 493.717 22.9869C493.53 28.4066 493.997 34.1067 495.118 40.0871C496.24 45.8806 496.707 53.4495 496.52 62.7938C496.333 71.7644 495.679 80.3612 494.558 88.5843C493.436 96.6204 492.315 104.937 491.194 113.534C490.072 122.13 489.512 131.849 489.512 142.688C489.512 158.947 491.474 173.151 495.399 185.298C499.51 197.446 506.145 206.884 515.302 213.612Z"
        fill="#5A5A5A"
        fillOpacity="1"
        mask="url(#maskT)"
      />
    </svg>
  );
});

export default TMask;
