import React from "react";

const SMask = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement> & { style?: React.CSSProperties }
>((props, ref) => {
  const { style, ...svgProps } = props;
  return (
    <svg
      width="795"
      height="313"
      viewBox="0 0 795 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <defs>
        <mask id="maskS">
          <path
            ref={ref}
            // d="M456.759 60.5001C456.759 60.5001 423.521 60.0428 403.259 65.5001C392.821 68.3116 386.136 68.8315 377.259 75.0001C369.999 80.045 364.594 83.1261 361.759 91.5001C359.567 97.9734 359.093 102.707 361.759 109C364.183 114.723 367.678 116.921 372.759 120.5C380.179 125.728 385.993 125.644 394.759 128C402.081 129.968 406.408 130.139 413.759 132C421.664 134.001 426.523 134.241 433.759 138C438.373 140.397 441.542 141.415 444.759 145.5C446.92 148.244 448.176 150.056 448.759 153.5C449.456 157.616 448.221 160.09 446.759 164C445.069 168.517 443.223 170.696 440.259 174.5C436.864 178.856 435 181.668 430.259 184.5C425.859 187.129 422.683 187.079 417.759 188.5C411.571 190.286 408.13 191.56 401.759 192.5C392.293 193.897 386.631 194.426 377.259 192.5C369.019 190.807 365.003 187.786 357.259 184.5C352.349 182.417 349.323 181.76 344.759 179C339.303 175.701 332.259 168.5 332.259 168.5"
            strokeLinecap="round"
            d="M433.759 68.9999C433.759 68.9999 423.521 60.0426 403.259 65.5C392.821 68.3114 386.136 68.8314 377.259 75C369.999 80.0448 364.594 83.1259 361.759 91.5C359.567 97.9733 359.093 102.707 361.759 109C364.183 114.723 367.678 116.921 372.759 120.5C380.179 125.728 385.993 125.644 394.759 128C402.081 129.968 406.408 130.139 413.759 132C421.664 134.001 426.523 134.241 433.759 138C438.373 140.397 441.542 141.415 444.759 145.5C446.92 148.244 448.176 150.056 448.759 153.5C449.456 157.616 448.221 160.09 446.759 164C445.069 168.517 443.223 170.696 440.259 174.5C436.864 178.856 435 181.668 430.259 184.5C425.859 187.129 422.683 187.079 417.759 188.5C411.571 190.286 408.13 191.56 401.759 192.5C392.293 193.897 386.631 194.425 377.259 192.5C369.019 190.807 365.003 187.786 357.259 184.5"
            stroke="white"
            strokeWidth="60"
            {...svgProps}
          />
        </mask>
      </defs>
      <path
        d="M385.303 215.067C374.463 215.067 365.493 213.292 358.391 209.741C351.289 206.19 345.87 201.705 342.132 196.285C338.581 190.865 336.806 185.259 336.806 179.465C336.806 175.728 337.927 172.644 340.17 170.214C342.599 167.598 345.963 166.29 350.262 166.29C352.878 166.29 355.401 166.757 357.83 167.691C360.447 168.439 363.063 169.373 365.68 170.495C368.483 171.429 371.567 172.27 374.931 173.018C378.481 173.765 382.5 174.139 386.985 174.139C397.637 174.139 406.141 173.018 412.495 170.775C418.849 168.346 424.175 164.514 428.474 159.281C426.231 156.478 422.493 154.236 417.261 152.554C412.215 150.872 406.421 149.376 399.88 148.068C393.526 146.76 386.891 145.172 379.977 143.303C373.249 141.247 366.988 138.63 361.194 135.453C355.588 132.089 351.009 127.698 347.458 122.278C343.907 116.671 342.132 109.476 342.132 100.692C342.132 90.6005 344.561 81.9102 349.421 74.6217C354.28 67.3331 360.634 61.3527 368.483 56.6805C376.519 52.0083 385.396 48.6444 395.114 46.5886C404.833 44.346 414.644 43.3181 424.549 43.505C431.277 43.505 436.884 45.0001 441.369 47.9902C446.041 50.7936 449.592 54.3444 452.022 58.6428C454.451 62.9412 455.666 67.5199 455.666 72.379C455.666 77.0512 454.451 80.7889 452.022 83.5922C449.592 86.2087 446.321 87.5169 442.21 87.5169C440.154 87.5169 438.192 87.4234 436.323 87.2365C434.641 86.8628 432.772 86.5824 430.716 86.3955C428.661 86.2086 426.044 86.1152 422.867 86.1152C412.588 86.1152 403.992 87.4234 397.077 90.0398C390.162 92.4694 385.209 96.394 382.219 101.814C384.275 105.178 387.732 107.888 392.591 109.943C397.637 111.812 403.431 113.401 409.972 114.709C416.513 116.017 423.241 117.512 430.156 119.194C437.257 120.876 443.705 123.212 449.499 126.202C455.479 129.193 460.245 133.211 463.795 138.257C467.533 143.303 469.402 149.844 469.402 157.88C469.402 170.962 465.477 181.801 457.628 190.398C449.966 198.808 439.78 205.069 427.072 209.18C414.364 213.105 400.441 215.067 385.303 215.067Z"
        fill="#5A5A5A"
        fillOpacity="1"
        mask="url(#maskS)"
      />
    </svg>
  );
});

export default SMask;
