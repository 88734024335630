import React from "react";

const UMask = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement> & { style?: React.CSSProperties }
>((props, ref) => {
  const { style, ...svgProps } = props;
  return (
    <svg
      width="795"
      height="313"
      viewBox="0 0 795 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <defs>
        <mask id="maskU">
          <path
            ref={ref}
            // d="M122.759 72.5C122.759 72.5 106.502 116.143 115.259 142C121.062 159.136 127.147 169.331 141.759 180C152.652 187.954 160.286 193.658 173.759 193C185.8 192.412 192.96 188.242 201.759 180C209.555 172.697 211.903 166.462 215.759 156.5C220.196 145.034 220.162 137.746 221.259 125.5C223.02 105.833 218.759 75 218.759 75"
            strokeLinecap="round"
            d="M120.5 97.5C120.5 97.5 106.503 116.143 115.259 142C121.062 159.136 127.147 169.331 141.759 180C152.652 187.954 160.286 193.658 173.759 193C185.8 192.412 192.96 188.242 201.759 180C209.555 172.697 211.903 166.462 215.759 156.5C220.197 145.034 220.162 137.746 221.259 125.5C223.02 105.833 218 97.5 218 97.5"
            stroke="white"
            strokeWidth="58"
            {...svgProps}
          />
        </mask>
      </defs>
      <path
        d="M174.217 212.544C162.069 212.544 151.043 210.021 141.138 204.975C131.233 199.929 122.729 193.388 115.627 185.352C108.713 177.316 103.386 168.719 99.6486 159.562C95.9109 150.404 94.042 141.714 94.042 133.491C94.042 125.081 94.7895 117.232 96.2846 109.943C97.7797 102.468 100.022 94.9922 103.013 87.5167C103.947 84.5265 105.629 82.097 108.059 80.2281C110.675 78.3592 113.572 77.4248 116.749 77.4248C124.224 77.4248 130.392 79.8543 135.251 84.7134C140.297 89.3856 142.82 95.6463 142.82 103.496C142.82 105.364 142.446 107.42 141.698 109.663C141.138 111.905 140.483 114.242 139.736 116.671C138.988 118.914 138.241 121.53 137.493 124.52C136.933 127.324 136.652 130.314 136.652 133.491C136.652 137.789 137.68 142.088 139.736 146.386C141.792 150.685 144.501 154.609 147.865 158.16C151.416 161.524 155.154 164.327 159.079 166.57C163.003 168.626 166.928 169.654 170.853 169.654C177.207 169.654 182.533 167.598 186.831 163.486C191.13 159.375 194.4 153.955 196.643 147.227C198.886 140.312 200.007 132.65 200.007 124.24C200.007 120.315 199.913 116.858 199.727 113.868C199.54 110.691 199.353 107.794 199.166 105.178C198.979 102.561 198.886 99.7578 198.886 96.7676C198.886 90.6003 200.007 85.8347 202.25 82.4708C204.679 79.1068 209.071 77.4248 215.425 77.4248C221.032 77.4248 225.704 78.733 229.442 81.3494C233.366 83.9659 236.356 88.0774 238.412 93.684C240.468 99.2906 241.496 106.392 241.496 114.989C241.496 136.855 238.506 155.076 232.525 169.654C226.545 184.044 218.509 194.79 208.417 201.892C198.325 208.993 186.925 212.544 174.217 212.544Z"
        fill="#5A5A5A"
        fillOpacity="1"
        mask="url(#maskU)"
      />
    </svg>
  );
});

export default UMask;
