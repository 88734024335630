import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Logo, { LOGO_WIDTH } from "./logo";

export default function App() {
  const headerRef = useRef(null);
  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const messageLinkRef = useRef(null);

  useEffect(() => {
    // Set initial state
    gsap.set([titleRef.current, paragraphRef.current, messageLinkRef.current], {
      autoAlpha: 0,
      y: 30,
    });

    // Create the animation timeline
    const tl = gsap.timeline({
      defaults: {
        duration: 0.55, // Duration of each animation
        ease: "power1.inOut", // Easing function for a gentle ease in
      },
    });

    tl.to([titleRef.current, paragraphRef.current, messageLinkRef.current], {
      autoAlpha: 1,
      y: 0,
      stagger: function (index) {
        return 0.06 * Math.pow(2, index); // Exponential growth of stagger delay
      },
    });
  }, []);

  return (
    <div className="overflow-hidden bg-white" style={{ height: "100vh" }}>
      <header
        ref={headerRef}
        className="absolute inset-x-0 z-50 mt-3"
        style={{ top: "2em" }}
      >
        <nav
          className="flex w-full items-center justify-center p-6 lg:px-8"
          aria-label="Global"
        >
          <a href="https://lujstn.com" aria-label="lujstn.com">
            <Logo
              style={{
                transform: "scale(0.12)",
                width: `${LOGO_WIDTH}px`,
              }}
            />
          </a>
        </nav>
      </header>

      <main className="isolate h-full">
        <div
          className="relative -z-10 h-full px-6 lg:px-8"
          style={{ height: "100%" }}
        >
          <div
            className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu opacity-30 blur-3xl"
            aria-hidden="true"
          >
            <div
              className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-8 opacity-25 blur-3xl xl:justify-end"
            aria-hidden="true"
          >
            <div
              className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>

          <div className="mx-auto flex h-full max-w-2xl flex-col justify-center text-center">
            <h2
              ref={titleRef}
              className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
            >
              You've hit one of my parked domains 🏗️
              <br />
              It <i>might</i> be available.
            </h2>
            <p
              ref={paragraphRef}
              className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600"
            >
              I probably bought this domain for a project, but not every project
              gets off the ground. If you're interested in purchasing this
              domain, ping me a message and I'll let you know if it's an option.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                ref={messageLinkRef}
                href={`mailto:lucas@lucasjohnston.co.uk?subject=Domain%20inquiry%20for%20${window.location.hostname}`}
                className="group/send text-sm font-semibold leading-6 text-indigo-600 transition-all hover:text-indigo-500"
              >
                Send me a message{" "}
                <span
                  aria-hidden="true"
                  className="inline-block transition-transform delay-100 duration-200 group-hover/send:translate-x-0.5"
                >
                  →
                </span>
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
