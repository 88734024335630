import React from "react";

const TMaskDash = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement> & { style?: React.CSSProperties }
>((props, ref) => {
  const { style, ...svgProps } = props;
  return (
    <svg
      width="795"
      height="313"
      viewBox="0 0 795 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <defs>
        <mask id="maskTDash">
          <path
            ref={ref}
            // d="M618.259 98.4998C618.259 98.4998 600.274 98.1663 588.759 98.4998C578.203 98.8055 547.239 102.7 536.759 104"
            strokeLinecap="round"
            d="M476 110C476 110 541.105 97.2751 584.549 92.4966C635.972 86.8404 648 99.0391 648 99.0391"
            stroke="white"
            strokeWidth="52"
            {...svgProps}
          />
        </mask>
      </defs>
      <path
        d="M509.135 128.952C513.247 132.316 519.227 132.503 527.076 129.513C528.855 128.825 530.621 128.173 532.374 127.555C538.723 125.319 544.9 123.542 550.904 122.224C558.567 120.542 566.79 119.327 575.573 118.58C584.357 117.832 594.356 117.178 605.569 116.617C609.306 116.244 612.11 114.935 613.979 112.693C615.848 110.263 616.782 107.46 616.782 104.283C616.782 99.7976 615.287 95.6861 612.297 91.9484C609.493 88.2106 605.569 85.2204 600.523 82.9778C595.477 80.5483 589.777 79.3335 583.423 79.3335C577.068 79.3335 570.247 79.7073 562.958 80.4548C555.857 81.0155 548.662 82.0434 541.373 83.5385C539.997 83.7855 538.644 84.0458 537.314 84.3195C537.296 84.3233 537.278 84.327 537.26 84.3307C531.569 85.5042 526.305 86.9221 521.47 88.5844C513.06 91.5746 507.827 95.2189 505.771 99.5173C503.902 103.816 502.968 108.675 502.968 114.094C502.968 120.636 505.023 125.588 509.135 128.952Z"
        fill="#5A5A5A"
        fillOpacity="1"
        mask="url(#maskTDash)"
      />
    </svg>
  );
});

export default TMaskDash;
