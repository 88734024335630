import React from "react";

const JMask = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement> & { style?: React.CSSProperties }
>((props, ref) => {
  const { style, ...svgProps } = props;
  return (
    <svg
      width="795"
      height="313"
      viewBox="0 0 795 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <defs>
        <mask id="maskJ">
          <path
            ref={ref}
            // d="M282.259 94.5C282.259 94.5 288.784 132.527 292.259 157C295.33 178.629 298.023 190.689 299.259 212.5C300.275 230.435 300.949 240.616 299.259 258.5C298.461 266.94 301.388 273.25 296.259 280C293.565 283.545 290.873 284.4 287.259 287C283.83 289.467 282.377 292.059 278.259 293C275.017 293.741 272.981 293.321 269.759 292.5C267.299 291.873 266.125 290.919 263.759 290C261.053 288.949 259.45 288.588 256.759 287.5C252.953 285.961 250.79 285.093 247.259 283C242.353 280.092 235.759 274 235.759 274"
            strokeLinecap="round"
            d="M286.5 116.5C286.5 116.5 288.784 132.527 292.259 157C295.33 178.629 298.023 190.689 299.259 212.5C300.275 230.435 300.949 240.616 299.259 258.5C298.461 266.94 301.388 273.25 296.259 280C293.565 283.545 290.873 284.4 287.259 287C283.83 289.467 282.377 292.059 278.259 293C275.017 293.741 272.981 293.321 269.759 292.5C267.299 291.873 266.125 290.919 263.759 290C261.053 288.949 259.191 288.088 256.5 287"
            stroke="white"
            strokeWidth="50"
            {...svgProps}
          />
        </mask>
      </defs>
      <path
        // d="M274.053 313.463C263.027 313.463 254.523 310.566 248.543 304.773C242.376 299.166 239.292 292.438 239.292 284.589C239.292 279.543 240.507 275.618 242.936 272.815C245.179 270.199 248.169 268.891 251.907 268.891C253.776 268.891 255.364 269.077 256.672 269.451C257.981 269.825 259.569 270.199 261.438 270.573C263.307 271.133 265.55 271.413 268.166 271.413C272.091 271.413 275.174 269.545 277.417 265.807C279.66 262.256 280.781 257.304 280.781 250.949C280.781 234.503 280.033 220.3 278.538 208.339C277.043 196.378 275.268 185.819 273.212 176.662C271.343 167.504 269.661 158.907 268.166 150.871C266.671 142.648 265.923 134.332 265.923 125.922C265.923 116.017 267.886 108.635 271.81 103.776C275.922 98.9168 281.342 96.4872 288.07 96.4872C293.302 96.4872 297.507 98.0758 300.684 101.253C303.861 104.243 306.291 109.569 307.973 117.232C311.15 134.238 313.766 150.404 315.822 165.729C318.065 181.054 319.747 195.911 320.868 210.301C321.99 224.505 322.55 238.708 322.55 252.912C322.55 272.722 318.065 287.766 309.094 298.045C300.311 308.324 288.63 313.463 274.053 313.463ZM290.873 79.1067C281.528 79.1067 273.96 76.6772 268.166 71.8181C262.559 66.7722 259.756 60.6984 259.756 53.5967C259.756 45.9343 262.373 40.0473 267.605 35.9358C272.838 31.8243 279.566 29.7686 287.789 29.7686C293.77 29.7686 298.909 31.0768 303.207 33.6932C307.506 36.3096 310.776 39.6736 313.019 43.7851C315.448 47.8966 316.663 52.0081 316.663 56.1196C316.663 61.7262 314.607 66.9591 310.496 71.8181C306.571 76.6772 300.03 79.1067 290.873 79.1067Z"
        d="M273.915 312.569C262.889 312.569 254.386 309.672 248.405 303.878C242.238 298.272 239.154 291.544 239.154 283.695C239.154 278.649 240.369 274.724 242.799 271.921C245.041 269.304 248.031 267.996 251.769 267.996C253.638 267.996 255.227 268.183 256.535 268.557C257.843 268.93 259.432 269.304 261.3 269.678C263.169 270.239 265.412 270.519 268.028 270.519C271.953 270.519 275.037 268.65 277.279 264.912C279.522 261.362 280.643 256.409 280.643 250.055C280.643 233.609 279.896 219.405 278.401 207.445C276.905 195.484 275.13 184.925 273.074 175.767C271.205 166.61 269.523 158.013 268.028 149.977C266.533 141.754 265.786 133.437 265.786 125.027C265.786 115.122 267.748 107.74 271.673 102.881C275.784 98.0223 281.204 95.5928 287.932 95.5928C293.165 95.5928 297.37 97.1813 300.547 100.358C303.724 103.349 306.153 108.675 307.835 116.337C311.012 133.344 313.629 149.51 315.685 164.834C317.927 180.159 319.609 195.017 320.73 209.407C321.852 223.61 322.412 237.814 322.412 252.017C322.412 271.827 317.927 286.872 308.957 297.15C300.173 307.429 288.492 312.569 273.915 312.569Z"
        fill="#5A5A5A"
        fillOpacity="1"
        mask="url(#maskJ)"
      />
    </svg>
  );
});

export default JMask;
