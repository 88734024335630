import React from "react";

const JMaskDot = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement> & { style?: React.CSSProperties }
>((props, ref) => {
  const { style, ...svgProps } = props;
  return (
    <svg
      width="795"
      height="313"
      viewBox="0 0 795 313"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <defs>
        <mask id="maskJDot">
          <path
            ref={ref}
            d="M287.5 29.5V73.5"
            strokeLinecap="round"
            fill="white"
            stroke="white"
            strokeWidth="50"
            {...svgProps}
          />
        </mask>
      </defs>
      <path
        d="M268.028 70.9236C273.822 75.7827 281.391 78.2122 290.735 78.2122C299.892 78.2122 306.433 75.7827 310.358 70.9236C314.47 66.0645 316.525 60.8317 316.525 55.2251C316.525 51.1136 315.31 47.0021 312.881 42.8906C310.638 38.779 307.368 35.4151 303.069 32.7986C298.771 30.1822 293.632 28.874 287.651 28.874C279.428 28.874 272.7 30.9298 267.467 35.0413C262.235 39.1528 259.618 45.0398 259.618 52.7021C259.618 59.8038 262.421 65.8777 268.028 70.9236Z"
        fill="#5A5A5A"
        fillOpacity="1"
        mask="url(#maskJDot)"
      />
    </svg>
  );
});

export default JMaskDot;
