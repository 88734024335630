import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import LogoLMaskSVG from "./lMask";
import LogoUMaskSVG from "./uMask";
import LogoJMaskSVG from "./jMask";
import LogoJMaskDotSVG from "./jMaskDot";
import LogoSMaskSVG from "./sMask";
import LogoTMaskSVG from "./tMask";
import LogoTMaskDashSVG from "./tMaskDash";
import LogoNMaskSVG from "./nMask";

// Key variables
export const LOGO_WIDTH = 795;
export const LOGO_HEIGHT = 313;

// Individual SVG Components
const LetterL = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement>
>((props, ref) => <LogoLMaskSVG ref={ref} {...props} />);
const LetterU = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement>
>((props, ref) => <LogoUMaskSVG ref={ref} {...props} />);
const LetterJ = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement>
>((props, ref) => <LogoJMaskSVG ref={ref} {...props} />);
const LetterJDot = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement>
>((props, ref) => <LogoJMaskDotSVG ref={ref} {...props} />);
const LetterS = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement>
>((props, ref) => <LogoSMaskSVG ref={ref} {...props} />);
const LetterT = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement>
>((props, ref) => <LogoTMaskSVG ref={ref} {...props} />);
const LetterTDash = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement>
>((props, ref) => <LogoTMaskDashSVG ref={ref} {...props} />);
const LetterN = React.forwardRef<
  SVGPathElement,
  React.SVGProps<SVGPathElement>
>((props, ref) => <LogoNMaskSVG ref={ref} {...props} />);

// Main Logo Animation Component
const LogoAnimation: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  const masksRef = useRef<SVGElement[]>([]);

  useEffect(() => {
    masksRef.current = masksRef.current.slice(0, 8);

    masksRef.current.forEach((mask, index) => {
      if (!mask || !(mask instanceof SVGPathElement)) {
        console.log(`mask is not SVGPathElement, it is ${typeof mask}`);
        return;
      } else {
        console.log("yay");
      }

      const length = mask.getTotalLength();
      gsap.set(mask, { strokeDasharray: length, strokeDashoffset: length }); // Set initial mask state

      gsap.fromTo(
        mask,
        {
          strokeDashoffset: length,
        },
        {
          strokeDashoffset: 0,
          duration: index > 5 ? 0.2 : index > 6 ? 0.1 : 0.28,
          delay: index * 0.12 + (index > 5 ? 0.22 : 0),
          ease: "power1.out",
        }
      );
    });
  }, []);

  return (
    <div
      className="relative"
      style={{
        ...style,
        transformOrigin: "top",
        margin: 0,
        padding: 0,
        width: LOGO_WIDTH,
        height: LOGO_HEIGHT,
      }}
    >
      <LetterL
        ref={(el: any) => (masksRef.current[0] = el)}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <LetterU
        ref={(el: any) => (masksRef.current[1] = el)}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <LetterJ
        ref={(el: any) => (masksRef.current[2] = el)}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <LetterS
        ref={(el: any) => (masksRef.current[3] = el)}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <LetterT
        ref={(el: any) => (masksRef.current[4] = el)}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <LetterN
        ref={(el: any) => (masksRef.current[5] = el)}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <LetterJDot
        ref={(el: any) => (masksRef.current[6] = el)}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <LetterTDash
        ref={(el: any) => (masksRef.current[7] = el)}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
    </div>
  );
};

export default LogoAnimation;
